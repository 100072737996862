<template>
  <div>
    <h3>Master Urusan</h3>
    <br />
    <CCard>
      <CCardBody>
       <div class="row">
          <div class="col-md-5">
            <div class="row mb-3">
              <label class="m-1 ml-3" for="">Search : </label>
              <input
                type="text"
                v-model="params.keyword"
                style="max-width: 200px"
                class="form-control form-control-sm mx-2"
                placeholder="Ketik disini"
              />
              <button @click="search()" class="btn btn-sm btn-success">
                Cari
              </button>
            </div>
          </div>
          <div
            :class="[
              'col-md-5',
              'ml-auto',
              user.role.is_opd == 0 || user.role.name.toLowerCase() == 'admin'
                ? ''
                : 'offset-md-2',
            ]"
          >
            <div class="row">
              <div
                :class="[
                  'col-md-6',
                  user.role.is_opd == 0 ||
                  user.role.name.toLowerCase() == 'admin'
                    ? ''
                    : 'offset-md-6',
                ]"
              >
                <div class="input-group input-group-sm mb-3">
                  <div class="input-group-prepend">
                    <label class="input-group-text" for="inputGroupSelect01"
                      >Per Halaman</label
                    >
                  </div>
                  <select
                    class="custom-select"
                    style="max-width: 100px"
                    id="inputGroupSelect01"
                    v-model="params.row"
                    @change="getData"
                  >
                    <!-- <option selected>Pilih...</option> -->
                    <option selected value="5">5</option>
                    <option value="10">10</option>
                    <option value="25">25</option>
                  </select>
                </div>
              </div>
              <div
                class="col"
                v-if="
                  user.role.is_opd == 0 ||
                  user.role.name.toLowerCase() == 'admin'
                "
              >
                <button
                  class="btn btn-sm btn-primary"
                  @click="create"
                >
                  Tambah Urusan
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-if="isSearching">
          <div class="col">
            <h3>
              <span class="badge bg-primary text-light text-bor my-auto">
                {{ searchOn }}&nbsp;&nbsp;
                <span
                  @click="searchOff"
                  class="badge bg-light text-dark text-center"
                  style="cursor: pointer"
                  >X</span
                >
              </span>
            </h3>
          </div>
        </div>
        <CDataTable
          class="table-striped"
          :items="computedItems"
          :itemsPerPage="5"
          :fields="fields"
          sorter
        >
          <template #report="item">
            <div>
              <td
                style="
                  min-width: 450px;
                  max-width: 500px !important;
                  white-space: normal;
                "
              >
                {{ item.item.report }}
              </td>
            </div>
          </template>
          <template #action="{ item }">
            <td class="py-2">
              <CButton
                @click="edit(item)"
                class="mr-2"
                color="warning"
                square
                size="sm"
              >
                Edit
              </CButton>
              <CButton
                @click="destroy(item.id)"
                color="danger"
                square
                size="sm"
              >
                Delete
              </CButton>
            </td>
          </template>
        </CDataTable>
        
        <pagination
          v-if="total !== items.length"
          v-model="page"
          :records="total"
          :per-page="5"
          @paginate="pagination"
        />
      </CCardBody>
    </CCard>
    <CModal
      :title="!isUpdate ? 'Tambah Urusan' : 'Update Urusan'"
      :color="!isUpdate ? 'primary' : 'warning'"
      size="lg"
      :show.sync="createModal"
    >
      <div class="row">
        <div class="col">
          <CInput
            v-model="form.code"
            label="Code"
            placeholder="ketik disini"
          />
          <CInput
            v-model="form.description"
            label="Uraian"
            placeholder="ketik disini"
          />
          <label for="">Parent</label>
          <Select2 v-model="form.parent_id" :options="parents" :settings="{ settingOption: value, settingOption: value }" />
          <br/>
          <label for="">Opd</label>
          <Select2 v-model="form.opd_id" :options="opds" :settings="{ settingOption: value, settingOption: value }" />
          <br/>
           <input type="checkbox" v-model="form.is_urusan_detail" @change="check(checked)"> Is Detail
          <br/> <br/>
          <div v-if="form.is_urusan_detail">
            <label for="">Rumus</label>
            <select
              name=""
              v-model="form.rumus"
              class="form-control"
              placeholder="Pilih"
              id=""
            >
              <option v-for="item in rumus" :value="item" :key="item">{{ item }}</option>
            </select>
            <br/>
            <table class="table table-bordered">
              <tr>
                <td>Parameter</td>
                <td>Template Rumus</td>
                <td></td>
              </tr>
              <tr v-for="(urusan_detail, index) in form.urusan_detail" :key="urusan_detail">
                <td>
                  <CInput
                    v-model="urusan_detail.description"
                    placeholder="Parameter"
                  />
                </td>
                <td>
                  <select
                    name=""
                    v-model="urusan_detail.rumus"
                    class="form-control"
                    placeholder="Pilih"
                    id=""
                  >
                    <option v-for="item in parameters" :value="item" :key="item">{{ item }}</option>
                  </select>
                <br/>
                </td>
                <td>
                  <button @click="deleteDetail(index)" class="btn btn-danger">
                    delete
                  </button>
                </td>
              </tr>
            </table>
            <button @click="addDetail" class="btn btn-primary">
              + tambah detail
            </button>
          </div>
        </div>
      </div>
      <template slot="footer">
        <div class="row">
          <button @click="closeModal" class="btn btn-secondary mr-3">
            Batal
          </button>

          <button @click="store" v-if="!isUpdate" class="btn btn-primary">
            Tambah
          </button>

          <button @click="update" v-if="isUpdate" class="btn btn-primary">
            Update
          </button>
        </div>
      </template>
    </CModal>
    <CModal
      :title="exportType"
      :color="exportType == 'Export Excel' ? 'success' : 'danger'"
      size="md"
      :show.sync="exportModal"
    >
      <CRow>
        <CCol sm="6">
          <CInput
            v-model="exportDataParams.firstdate"
            label="Dari"
            type="date"
          />
        </CCol>
        <CCol sm="6">
          <CInput
            v-model="exportDataParams.lastdate"
            label="Sampai"
            type="date"
          />
        </CCol>
      </CRow>
      <template slot="footer">
        <div class="row">
          <button @click="exportModal = false" class="btn btn-secondary mr-3">
            Batal
          </button>

          <button
            v-if="exportType == 'Export Excel'"
            @click="exportExcel"
            class="btn btn-success"
          >
            Export
          </button>

          <button
            v-if="exportType == 'Export PDF'"
            @click="exportPDF"
            class="btn btn-danger"
          >
            Export
          </button>
        </div>
      </template>
    </CModal>
  </div>
</template>

<script>
import * as data from "../../model/urusan";
import FileSaver from "file-saver";
import Select2 from 'v-select2-component'

export default {
  data() {
    return {
      myOptions: [{id: 'key', text: 'value'}, {id: 'key', text: 'value'}],
      createModal: false,
      exportModal: false,
      exportType: "",
      fields: data.fields,
      isUpdate: false,
      exportDataParams: {},
      items: [],
      parents: [],
      opds: [],
      roles: [],
      user: JSON.parse(localStorage.getItem("user")),
      page: 1,
      total: 0,
      form: {
        urusan_detail: []
      },
      params: {
        sorttype: "ASC",
        sortby: "code",
        row: 5,
        page: 1,
        role_id: 0,
        keyword: "",
      },
      isSearching: false,
      searchOn: "",
      rumus: [
        'TEMPLATE_100', 'TEMPLATE_1000', 'TEMPLATE_10000', 'TEMPLATE_LAJU', 'TEMPLATE', 'TEMPLATE_YA_TIDAK', 'TEMPLATE_SUDAH_BELUM'
      ],
      parameters: [
        'parameter_1', 'parameter_2', 'parameter_3', 'parameter_4', 'parameter_5', 'parameter_6'
      ]
    };
  },
  components: {Select2},
  methods: {
    search() {
      if (this.params.keywordexportExcel != "") {
        this.isSearching = true;
        this.getData();
        this.searchOn = this.params.keyword;
        this.params.keyword = "";
      } else {
        this.$toast.error("Inputan tidak boleh kosong !!");
      }
    },
    openModalExcel() {
      this.exportModal = true;
      this.exportType = "Export Excel";
    },

    openModalPDF() {
      this.exportModal = true;
      this.exportType = "Export PDF";
    },
    exportExcel() {
      var loading = this.$loading.show();
      this.$store
        .dispatch("report/exportReport", this.exportDataParams)
        .then((resp) => {
          loading.hide();
          FileSaver.saveAs(
            resp.data,
            "Laporan_Harian_" +
              this.exportDataParams.firstdate +
              "-" +
              this.exportDataParams.lastdate
          );
          this.exportModal = false;
          this.exportDataParams = {};
        })
        .catch((e) => {
          this.$toast.error(e);
          loading.hide();
        });
    },

    exportPDF() {
      this.$router.push({ name: "ExportPDF", query: this.exportDataParams });
    },

    searchOff() {
      this.isSearching = false;
      this.getData();
    },
    getData() {
      console.log(this.params)
      var loading = this.$loading.show();
      if (this.user.role_id != null) {
        this.params.role_id = this.user.role_id;
      }
      this.$store
        .dispatch("urusan/getUrusan", this.params)
        .then((resp) => {
          this.items = resp.data.data;

          this.total = resp.data.total;
          loading.hide();
        })
        .catch((e) => {
          this.$toast.error(e);
          loading.hide();
        });
    },
    getParent() {
      var loading = this.$loading.show();
      if (this.user.role_id != null) {
        this.params.role_id = this.user.role_id;
      }
      this.$store
        .dispatch("urusan/getUrusan", {
          sorttype: "asc",
          sortby: "code",
          row: 500,
          page: 1,
          role_id: 0,
          keyword: "",
      })
        .then((resp) => {
          this.parents = [
            {id: 0, text: 'No Parent'}
          ];
          resp.data.data.forEach(element => {
            this.parents.push({
              id: element.id,
              text: element.description
            })
          })
          loading.hide();
        })
        .catch((e) => {
          this.$toast.error(e);
          loading.hide();
        });
    },
    getOpd() {
      var loading = this.$loading.show();
      this.$store
        .dispatch("role/getRole", {
          sorttype: "desc",
          sortby: "name",
          row: 500,
          page: 1,
          role_id: 0,
          keyword: "",
      })
        .then((resp) => {
          this.opds = [
             {id: 0, text: 'No Opd'}
          ];
          resp.data.data.forEach(element => {
            if (element.is_opd == 1) {
              this.opds.push({
                id: element.id,
                text: element.name
              });
            }
          });
          loading.hide();
        })
        .catch((e) => {
          this.$toast.error(e);
          loading.hide();
        });
    },
    addDetail() {
      this.form.urusan_detail.push({
        description: "",
        tahun: new Date().getFullYear(),
        rumus: "",
        value: 0
      })
    },
    deleteDetail(index) {
     ( this.form.urusan_detail.splice(index, 1))
    },
    create() {
      this.createModal = true;
      this.isUpdate = false;
      //this.form.is_urusan_detail = false;
      this.getOpd();
      this.getParent();
    },
    store() {
      var loading = this.$loading.show();
      if (!this.form.is_urusan_detail) {
        this.form.is_urusan_detail = false;
      }
      this.$store
        .dispatch("urusan/addUrusan", this.form)
        .then((resp) => {
          this.$toast.success("Berhasil menambah Uraian");
          loading.hide();
          this.closeModal();
          this.getData();
        })
        .catch((e) => {
          this.$toast.error(e);
          loading.hide();
        });
    },
    edit(item) {
      this.isUpdate = true;
      this.form = item;
      this.form.id = item.id;
      this.createModal = true;
      this.getOpd();
      this.getParent();
    },
    update() {
      if (this.isUpdate) {
        var loading = this.$loading.show();
        this.$store
          .dispatch("urusan/updateUrusan", {
            id: this.form.id,
            data: this.form,
          })
          .then((resp) => {
            this.$toast.success("Berhasil memperbarui Uraian");
            loading.hide();
            this.closeModal();
            this.getData();
          })
          .catch((e) => {
            this.$toast.error(e);
            loading.hide();
          });
      } else {
        this.$toast.error("Tidak bisa memperbarui Uraian");
      }
    },
    destroy(id) {
      if (confirm("Data akan dihapus !!")) {
        var loading = this.$loading.show();
        this.$store
          .dispatch("urusan/deleteUrusan", id)
          .then(() => {
            this.$toast.success("Berhasil menghapus laporan");
            if (this.total == this.params.row + 1) {
              this.page--;
              this.params.page = this.page;
            }
            this.getData();
            loading.hide();
          })
          .catch((e) => {
            this.$toast.error(e);
            loading.hide();
          });
      }
    },
    closeModal() {
      this.createModal = false;
      this.isUpdate = false;
      this.form = {
        urusan_detail: []
      };
    },
    pagination(page) {
      this.page = page;
      this.params.page = page;
      this.getData();
    },
  },
  computed: {
    computedItems() {
      return this.items.map((item) => {
        return {
          ...item,
          rumus: item.rumus ? item.rumus : '-',
          parent_name: item.parent ? item.parent.description : "-",
          opd_name: item.opd ? item.opd.name : "-",
        };
      });
    },
  },
  mounted() {
    this.getData();
  },
};
</script>