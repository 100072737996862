export const fields = [
    // { key: 'index', label: "No", _style:'min-width:50px'},
    // { key: 'select', label: "", _style:'min-width:50px' },
    { key: 'code', label: "Code", _style:'min-width:50px' },
    { key: 'description', label: "Urusan/Indikator", _style:'min-width:100px' },
    { key: 'rumus', label: "Rumus", _style:'min-width:100px' },
    { key: 'parent_name', label: "Parent", _style:'min-width:100px' },
    { key: "is_urusan_detail", label: "Is Detail", _style:'min-width:50px'},
    { key: 'opd_name',label: "Opd", _style:'min-width:100px;' },
    { key: "action", label: 'Aksi', _style: 'min-width:75px'}
]
